const clickAttr = 'data-click-event';
const clickSelector = '[' + clickAttr + ']';

let events = {};

events.init = function init() {

	let clickEls = document.querySelectorAll(clickSelector);

	if (clickEls.length) {
		clickEls.forEach(el => this.registerClickListener(el));
	}

};

events.registerClickListener = function registerClickListener(el) {

	let eventAction = el.getAttribute(clickAttr);

	console.log('Event Clicked');

	el.addEventListener(
		'click',
		function (e) {
			this.trackEvent('Click', eventAction);
		}
	);

};

events.registerSubmitListener = function registerSubmitListener(el) {

	let eventAction = el.getAttribute(submitAttr);

	el.addEventListener(
		'submit',
		function (e) {
			events.trackEvent('Submit', eventAction);
		}
	);

};

events.trackEvent = function trackEvent(category, action, label, value) {

	let method = 'ga';

	let hit = {
		'hitType': 'event', // Required
		'eventCategory': category, // Required
		'eventAction': action, // Required
		'eventLabel': label,
		'eventValue': (value || 1)
	};

	if (SITE.config.devMode) {
		log("Dev mode enabled; NOT Sending tracked event.", hit);
	}

	if (!SITE.config.devMode) {

		log("Sending tracked event: " + method, hit);

		if (method === 'ga') {
			ga('send', hit);
		}

		if (method === 'gtag') {
			gtag('event', hit.action, {
				'event_category': hit.category,
				'event_label': hit.label,
				'value': hit.value,
			});
		}

	}

};

export {
	events
}
