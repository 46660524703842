const hs = {};

hs.initForm = function initForm(target, formId, submitText, eventAction) {
	if (!target) return;
	formId = formId || '';
	submitText = submitText || 'Subscribe';

	document.getElementById('hubspotFormsScript').addEventListener('load', function (event) {
		hbspt.forms.create({
			target: target,
			css: '',
			portalId: '300810',
			formId: formId,
			translations: {
				en: {
					submitText: submitText,
					required: "We need your email address!"
				}
			}
		});
		let formExist = setInterval(function() {
			if (document.getElementById('hsForm_' + formId)) {
				clearInterval(formExist);
				hs.finishForm(target, eventAction);
			}
		}, 500);
	});
};

hs.finishForm = function finishForm(target, eventAction) {
	document.querySelectorAll('.hs-input').forEach(function (node, index, list) {
		node.setAttribute('autocomplete', 'off');
		node.setAttribute('autocapitalize', 'off');
	});

	SITE.events.trackEvent('Subscription Form Loaded', eventAction);
	document.querySelector(target + ' > form').addEventListener('submit', function(event) {
		SITE.events.trackEvent('Subscription Form Submitted', eventAction);
	});
};

export { hs };
